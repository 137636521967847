import React from 'react'
import Nav from './nav/Nav'
import Container from './container/Container'
import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import { useAppSelector } from '../../hooks/store.hook'

export default function SecondeLayout() {

    const { model: { isOpen } } = useAppSelector(s => s)

    return (
        <Box
            component="div"
            sx={{
                filter: isOpen ? "blur(5.44px)" : "none"
            }}
        >
            <Nav />
            <Container>
                <Outlet />
            </Container>
        </Box>
    )
}
