import React, { useEffect } from 'react';
import { RouterProvider } from "react-router-dom";
import { useAppDispatch, useAppSelector } from './hooks/store.hook';
import routes from './config/routes';
import "./App.css"
import Success from './components/sucsses/Success';
import Errors from './components/errors/Errors';
import Loading from './components/loading/Loading';
import { getUser } from './store/thunk-actions/user-action';
import { useGetOrders } from './hooks/get-orders.hook';
import { getOrders, restOrders } from './store/slices/order.slice';
import { setErrorMessages } from './store/slices/error.slice';

const App = () => {

    const dispatch = useAppDispatch();

    const {
        user: { isLoggedIn, loading },
        order: { page, limit }
    } = useAppSelector(s => s)

    const { isLoading, error, data } = useGetOrders({ page, limit }, isLoggedIn);


    useEffect(() => {
        dispatch(getUser())
    }, []);

    useEffect(() => {
        if (data) {
            dispatch(getOrders(data))
        } else {
            dispatch(restOrders(data))
        }

        if (error)
            dispatch(setErrorMessages(error))
    }, [dispatch, data, page])

    // console.log("orders = ", orders)

    return (
        <>

            {loading ? <Loading /> : <RouterProvider router={routes} />}
            <Success />
            <Errors />
        </>
    )
}

export default App